import React from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { Layout } from "components/layout"
import { SEO } from "components/seo/seo"
import { GenericHero } from "components/hero/GenericHero"
import { ContentBlocks } from "components/content-blocks/ContentBlocks"
import { GlobalFooter } from "components/global-footer/GlobalFooter"
import { EMPTY_PAGE } from "assets/helper/EmptyPage"

type PageContext = PageProps["pageContext"] & {
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  contentUrlPathCodex: Record<string, string>
  linkedPagesUrlPathCodex: Record<string, string>
}

interface NotFoundPageProps extends PageProps {
  pageContext: PageContext
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({
  location,
  pageContext,
}) => {
  const { pathname } = location
  const { contentUrlPathCodex, linkedPagesUrlPathCodex } = pageContext
  const data = useStaticQuery(get404)

  const [page] = data.allStrapiPage.nodes
  const { content, global_footer, hero } = page ?? EMPTY_PAGE

  return (
    <Layout
      contentUrlPathCodex={contentUrlPathCodex}
      location="/404"
      pathName={pathname}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
    >
      <SEO title="404: Not found" />
      {hero && <GenericHero banner={hero} />}

      {content?.length > 0 && <ContentBlocks strapiContent={content} />}

      {global_footer?.content?.length > 0 && (
        <GlobalFooter content={global_footer.content} />
      )}
    </Layout>
  )
}

const get404 = graphql`
  query fourOhFourPage {
    allStrapiPage(filter: { slug: { eq: "404" } }) {
      nodes {
        hero {
          heading
          sub_heading_rich {
            data {
              sub_heading_rich
            }
          }
        }
        content {
          ...StrapiContentBlockFragment
          ...StrapiCouponBannerFragment
          ...StrapiCTABannerFragment
          ...StrapiFaqGroupBannerFragment
          ...StrapiFormFragment
          ...StrapiHighlightsGroupFragment
          ...StrapiHtmlEmbedFragment
          ...StrapiLogoSliderFragment
          ...StrapiMultiContentBlocksFragment
          ...StrapiNavigationTilesFragment
          ...StrapiServiceAreaGroupFragment
          ...StrapiServiceComparisonFragment
          ...StrapiServiceFragment
          ...StrapiTabbedContentBannerFragment
        }
        global_footer {
          ...StrapiGlobalFooterFragment
        }
      }
    }
  }
`

export default NotFoundPage
